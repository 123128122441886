.h1 {
  color: #009de0;
  text-transform: uppercase;
  line-height: 1.1;
  font-size: 2.4rem;
}

.d-none {
  display: none!important;
}

.section-teaser {
  padding: 0;
}

.section-teaser .teaser-preview {
  height: 290px;
  width: 100%;
}

.teaser-preview--small {
  height: 240px!important;
}

.section-imgtextstart {
  margin-top: 30px;
}

.imgtextstart__item {
  display: inline-block;
  margin-bottom: 30px;
}

.imgtextstart__center {
  position: relative;
  margin: auto;
}

.imgtextstart__img {
  width: 100%;
}

.imgtextstart__text ul {
  padding-left: 20px;
}

.imgtextstart__text ul li::marker {
  color: #009de0;
}

.section-iconstrip {
  margin: 30px 0;
}

.section-iconstrip h2 {
  margin-bottom: 20px;
  text-align: center;
}

.iconstrip__item {
  margin: auto auto 20px;
}

.iconstrip__item:last-of-type {
  margin-bottom: 0;
}

.iconstrip__img {
  margin-left: 50%;
  transform: translateX(-50%);
  height: 48px;
  width: auto;
}

.iconstrip__text {
  text-align: center;
  margin: 10px 0 20px;
  font-weight: 600;
}

footer {
  padding-top: 10px;
}

.footer__headline {
  margin-top: 20px;
  font-size: 18px;
  color: #FFF;
}

.footer-menu {
  margin: 0;
}

.footer__d-none {
  display: none;
}

.footer-logos {
  margin-left: 0;
}

/* Standardlayout */
.header-image-wrapper {
  background-color: #5e5148;
  height: 5px;
}

#sub-page-container .page-content {
  margin-top: 30px!important;
}
/* Standardlayout ENDE */

@media (max-width: 425px) {

  .page-content img {
    width: 100%!important;
    height: auto!important;
    margin: 10px 0;
  }
}

@media (max-width: 767px) {

  .section-news_slider {
    padding-top: 30px;
  }

  .news-slider-xs {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .section-news_slider .pull-right {
    padding: 0 15px;
  }

  .section-news_slider .news-slider--item {
    height: 320px;
  }

  .news-slider--content {
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .section-imgtextstart .h1 {
    margin-top: 0;
  }

  .navbar {
    box-shadow: 0 5px 10px -5px rgba(94, 81, 72, 0.29);
  }

  .navbar-fixed-top.shrink .lower-nav {
    border: none;
    box-shadow: 0 5px 10px -5px rgba(94, 81, 72, 0.29);
  }

  nav .navbar-brand {
    width: 130px;
    padding-top: 12px;
  }

  .section-header_slider {
    border-top: none;
    margin-bottom: 35px;

    img {
      border-top: 5px solid #5e5148;
    }
  }

  .section-teaser .teaser-preview {
    margin-bottom: 35px;
  }

  .imgtextstart__item {
    margin-bottom: 0;
    display: flex;
  }

  .section-news_slider {
    margin-top: 50px;
  }

  .news-slider--image-wrapper {
    float: left;
  }

  .section-news_slider .pull-right {
    padding-left: 15px;
  }

  footer {
    margin-top: 50px;
  }

  /* Standardlayout */
  .header-image-wrapper {
    height: 50px;
  }

  #sub-page-container .page-content {
    margin-top: 0px!important;
  }
  /* Standardlayout ENDE */
}

@media (min-width: 992px) {
  nav .navbar-brand {
    width: 220px;
    padding-top: 5px;
  }

  .section-teaser .teaser-preview {
    height: 310px;
  }

  .section-news_slider {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .section-imgtextstart {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .section-iconstrip h2 {
    margin-bottom: 40px;
    text-align: left;
  }

  .section-iconstrip {
    margin: 60px 0;
  }

  .footer__headline {
    margin-bottom: 20px;
  }

  footer {
    padding-bottom: 0;

    a:hover {
      color: #FFF;
    }
  }

  footer .icon-wrapper {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  footer .copy {
    margin-top: 12px;
  }
}

@media (min-width: 1200px) {
  nav .navbar-brand {
    width: 315px;
    padding-top: 0;
  }

  .section-header_slider {
    margin-bottom: 60px;
  }

  .slider-overlay {
    bottom: 45px;
  }

  .section-header_slider .slick-prev {
    margin-left: 400px;
    top: 86%;
  }

  .section-header_slider .slick-next {
    margin-left: 470px;
    top: 86%;
  }

  .section-header_slider .slider--item {
    height: 500px;
  }

  .section-teaser .teaser-preview {
    margin-bottom: 35px;
  }

  footer {
    margin-top: 60px;
  }
}